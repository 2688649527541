<template>
  <div class="footer-content">
    <div class="Infor">
      <div class="perModule">
        <div class="spanDiv">
          <span> 股东单位 </span>
        </div>
        <ul>
          <li v-for="(item, index) in BottomList.SHAREHOLDER" :key="index">
            <a :href="item.address" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="perModule">
        <div class="spanDiv">
          <span> 友情链接 </span>
        </div>
        <ul>
          <li v-for="(item, index) in BottomList.FRIENDLY_LINKS" :key="index">
            <a :href="item.address" target="_blank">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="perModule">
        <div class="spanDiv">
          <span> 联系我们 </span>
        </div>
        <ul v-for="(item, index) in BottomList.CONTACT_US" :key="index">
          <li>业务咨询电话：</li>
          <li style="font-size: 1.375rem">
            <span class="el-icon-phone"></span>{{ item.phone }}
          </li>
          <li>地址：{{ item.address }}</li>
          <li>邮箱：{{ item.email }}</li>
          <li>邮编：{{ item.zipCode }}</li>
        </ul>
      </div>
      <div class="perModule">
        <div class="spanDiv">
          <span> 纪检监察举报 </span>
        </div>
        <ul v-for="(item, index) in BottomList.DISCIPLINE" :key="index">
          <li>网上举报：{{ item.email }}</li>
          <li>举报电话：{{ item.phone }}</li>
          <li>来信请寄：广西南宁市青秀区民族大道152号铁投大厦25楼2521室</li>
          <li>邮编：530022</li>
          <li>来访请到：广西南宁市青秀区民族大道152号铁投大厦25楼2521室</li>
<!--          <li @click="toReport" style="cursor: pointer">信访举报指南</li>-->
        </ul>
      </div>
    </div>

    <div class="companyState" style="cursor: pointer" @click="addRoutetFn">
      Copyright © 广西捷通高速科技有限公司 桂ICP备15007608号
    </div>
  </div>
</template>

<script>
import { searchBottomField } from '@/api/HomePage/BottomField';
export default {
  name: 'JietongofficialwebsiteuiIndex',

  data() {
    return {
      BottomList: [],
    };
  },

  mounted() {},

  methods: {
    addRoutetFn() {
      var https = 'https://beian.miit.gov.cn/#/Integrated/index';
      window.open(https, '_blank');
    },
    toReport() {
      this.$router.push('/layout/report');
    },
    getList() {
      searchBottomField().then((request) => {
        this.BottomList = request.data.data.data;
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.footer-content {
  width: 100%;
  margin-top: 1.25rem;
  // height:21.25rem;
  background-image: url('../../../../assets/allImage/homePage/dibutu @1x.png');
  background-size: cover; /* 图片尺寸自适应 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  font-size: 0.875rem;
  .Infor {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    .perModule {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 3.75rem;
      text-align: left;
      .spanDiv {
        width: 100%;
        border-bottom: 0.125rem solid #0aa4ee;
        span {
          font-size: 1.125rem;
          border-bottom: 0.125rem solid #f277aa;
        }
      }
      ul {
        padding: 0;
        li {
          font-size: 0.875rem;
          list-style-type: none;
          padding: 0.375rem 0;
          a {
            text-decoration: none;
          }
          a:link {
            color: white;
          }
          a:visited {
            color: white;
          }
        }
      }
    }
  }
  .companyState {
    background-color: #222222;
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
