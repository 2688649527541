<template>
  <!-- 二级导航内容页 -->
  <div class="app-content">
    <!-- 二级导航挂载页 -->
    <router-view></router-view>
    <BottomField />
  </div>
</template>

<script>
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
export default {
  components: {
    BottomField,
  },
  data() {
    return {
      isStatus: 0,
    };
  },
  created() {
    console.log('参数', this.$route.query);
  },
};
</script>

<style lang="scss" scoped>
.app-content {
  width: 100%;
}
</style>
